import React, {useContext} from 'react';
import Certificate from "../../models/Certificate";
import XactFooterButton from "../common/XactFooterButton";
import PartnerContext from "../../partner-context";
import {decodeFriendlyName} from "../../utils/certificates";
import CertificatesGroupEntry from "./CertificatesGroupEntry";
import GroupedCertificates from "../../models/GroupedCertificates";

interface CertificatesGroupProps {
  certificatesGroup: GroupedCertificates;
  renameCertificates: (certificatesGroup: GroupedCertificates) => void;
  removeCertificates: (certificatesGroup: GroupedCertificates) => void;
}

const CertificatesGroup: React.FC<CertificatesGroupProps> = (props: CertificatesGroupProps) => {
  const decodedFriendlyName = decodeFriendlyName(props.certificatesGroup.friendlyName);
  const partner = useContext(PartnerContext);

  // The type is everything after the last dash e.g: myCert-SIGN>>type=SIGN , myOldCert-CACERT-BACKUP>>type=BACKUP
  const getCertificateType = (certificate: Certificate): string =>
    certificate.friendlyName.substring(certificate.friendlyName.lastIndexOf("-") + 1);

  const groupEntries: Array<any> = props.certificatesGroup.certificates
    .map(certificate =>
      <CertificatesGroupEntry
        type={getCertificateType(certificate)}
        certificate={certificate}
      />
    );

  const handleRemoveCertificatesClick = () =>
    props.removeCertificates(props.certificatesGroup);

  const handleRenameCertificatesClick = () =>
    props.renameCertificates(props.certificatesGroup);

  return (
    <div className="certificate-group-box">
      <div className={`certificate-group-box-header ${partner}`}>
        {decodedFriendlyName}
      </div>
      {groupEntries}
      <div className={`row m-0 justify-content-end align-items-center certificate-group-box-footer ${partner}`}>
        <XactFooterButton
          id={"remove-certificates-" + props.certificatesGroup.friendlyName}
          i18nKey={"support.page.manage.certificates.delete"}
          onClick={handleRemoveCertificatesClick}
        />
        <XactFooterButton
          id={"rename-certificates-" + props.certificatesGroup.friendlyName}
          i18nKey={"support.page.manage.certificates.rename"}
          onClick={handleRenameCertificatesClick}
        />
      </div>
    </div>
  );
};

export default CertificatesGroup;
