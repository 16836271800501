export enum TroubleshootingKey {
  BROWSER_SUPPORT = "support.page.troubleshooting.browser.support",
  NATIVE_PROMISES_NOT_SUPPORTED = "support.page.troubleshooting.native.promises.not.supported",
  WEBCRYPTO_MISSING = "support.page.troubleshooting.webcrypto.missing",
  SHA_512_NOT_SUPPORTED = "support.page.troubleshooting.sha512.not.supported",
  KEY_GENERATION_FAILED = "support.page.troubleshooting.key.generation.failed",
  SIGNING_FAILED = "support.page.troubleshooting.signing.failed",
  SIGNATURE_VERIFICATION_FAILED = "support.page.troubleshooting.signature.verification.failed",
  INDEXED_DB_INIT_FAILED = "support.page.troubleshooting.indexed.db.init.failed",
  INDEXED_DB_READ_FAILED = "support.page.troubleshooting.indexed.db.read.failed",
  INDEXED_DB_EMPTY = "support.page.troubleshooting.indexed.db.empty",
  CSR_GENERATION_FAILED = "support.page.troubleshooting.csr.generation.failed",
  CLEAR_INDEXED_DB= "support.page.troubleshooting.clear.indexed.db",
  MAKE_KEYS_UNEXTRACTABLE= "support.page.troubleshooting.make.keys.unextractable",
  TEST_P12_DOWNLOAD= "support.page.troubleshooting.test.p12.download",
  GET_AUTHENTICATOR_APP= "support.page.troubleshooting.get.authenticator.app",
}

export const troubleshootingKeys: Array<TroubleshootingKey> = Object.values(TroubleshootingKey);
