import React from "react";
import clearstreamPasswordIcon from "./images/clearstream/password_icon.png";
import cetipPasswordIcon from "./images/cetip/password_icon.png";
import asxPasswordIcon from "./images/asx/password_icon.png";
import stratePasswordIcon from "./images/strate/password_icon.png";
import hqlaPasswordIcon from "./images/hqla/password_icon.png";
import hsbcPasswordIcon from "./images/hsbc/password_icon.png";
import tmxPasswordIcon from "./images/tmx/password_icon.png";
import cfclPasswordIcon from "./images/cfcl/password_icon.png";
import clearstreamUserIcon from "./images/clearstream/user_icon.png";
import cetipUserIcon from "./images/cetip/user_icon.png";
import asxUserIcon from "./images/asx/user_icon.png";
import strateUserIcon from "./images/strate/user_icon.png";
import hqlaUserIcon from "./images/hqla/user_icon.png";
import hsbcUserIcon from "./images/hsbc/user_icon.png";
import tmxUserIcon from "./images/tmx/user_icon.png";
import cfclUserIcon from "./images/cfcl/user_icon.png";
import clearstreamLogo from "./images/clearstream/clearstream_logo.png"
import cetipLogo from "./images/cetip/cetip_logo.png"
import asxLogo from "./images/asx/asx_logo.png"
import hqlaLogo from "./images/hqla/hqla_logo.png"
import hsbcLogo from "./images/hsbc/hsbc_logo.png"
import tmxLogo from "./images/tmx/tmx_logo.png"
import strateLogo from "./images/strate/strate_logo.png"
import cfclLogo from "./images/cfcl/cfcl_logo.png"

export enum Partner {
  Clearstream = 'clearstream',
  Strate = 'strate',
  Cetip = 'cetip',
  Asx = 'asx',
  Hqla = 'hqla',
  Hsbc = 'hsbc',
  Tmx = 'tmx',
  Cfcl = 'cfcl',
  Undefined = 'undefined',
}

export const partners = [
  Partner.Clearstream,
  Partner.Strate,
  Partner.Asx,
  Partner.Cetip,
  Partner.Hqla,
  Partner.Hsbc,
  Partner.Tmx,
  Partner.Cfcl,
  Partner.Undefined
];

export const userIconForPartner = new Map<Partner, any>();
userIconForPartner.set(Partner.Clearstream, clearstreamUserIcon);
userIconForPartner.set(Partner.Undefined, clearstreamUserIcon);
userIconForPartner.set(Partner.Cetip, cetipUserIcon);
userIconForPartner.set(Partner.Asx, asxUserIcon);
userIconForPartner.set(Partner.Strate, strateUserIcon);
userIconForPartner.set(Partner.Hqla, hqlaUserIcon);
userIconForPartner.set(Partner.Hsbc, hsbcUserIcon);
userIconForPartner.set(Partner.Tmx, tmxUserIcon);
userIconForPartner.set(Partner.Cfcl, cfclUserIcon);

export const passwordIconForPartner = new Map<Partner, any>();
passwordIconForPartner.set(Partner.Clearstream, clearstreamPasswordIcon);
passwordIconForPartner.set(Partner.Undefined, clearstreamPasswordIcon);
passwordIconForPartner.set(Partner.Cetip, cetipPasswordIcon);
passwordIconForPartner.set(Partner.Asx, asxPasswordIcon);
passwordIconForPartner.set(Partner.Strate, stratePasswordIcon);
passwordIconForPartner.set(Partner.Hqla, hqlaPasswordIcon);
passwordIconForPartner.set(Partner.Hsbc, hsbcPasswordIcon);
passwordIconForPartner.set(Partner.Tmx, tmxPasswordIcon);
passwordIconForPartner.set(Partner.Cfcl, cfclPasswordIcon);

export const partnerLogo = new Map<Partner, any>();
partnerLogo.set(Partner.Clearstream, clearstreamLogo);
partnerLogo.set(Partner.Undefined, clearstreamLogo);
partnerLogo.set(Partner.Cetip, cetipLogo);
partnerLogo.set(Partner.Asx, asxLogo);
partnerLogo.set(Partner.Strate, strateLogo);
partnerLogo.set(Partner.Hqla, hqlaLogo);
partnerLogo.set(Partner.Hsbc, hsbcLogo);
partnerLogo.set(Partner.Tmx, tmxLogo);
partnerLogo.set(Partner.Cfcl, cfclLogo);

const PartnerContext = React.createContext(
  Partner.Undefined // default value
);

export default PartnerContext;
